import { fiatAccountStatus } from "@/views/automate/lib/fiat-links";
export default {
    data() {
        return {
            status: null,
            gas: null,
            token: null,
        };
    },
    computed: {
        api() {
            return this.$store.getters["user/api"];
        },
        batches() {
            return this.$store.getters["user/batches"];
        },
        statusError() {
            return this.gas?.notEnoughGas || this.status?.notEnoughTokens;
        },
        statusWarning() {
            return this.gas?.gasWarning || this.status?.tokenWarning;
        },
    },
    watch: {
        link: {
            immediate: true,
            handler(val) {
                this.loadStatus(val);
            },
        },
    },
    methods: {
        loadStatus(val) {
            if (val) {
                const api = this.api;
                fiatAccountStatus(this.config, val, api, this.batches)
                    .then(({ status, gas }) => {
                    this.status = status;
                    this.gas = gas;
                })
                    .catch(console.error);
                api.contracts
                    .getContract({ chainId: val.action.chain, address: val.action.address })
                    .then((it) => (this.token = it))
                    .catch(console.error);
            }
        }
    }
};
