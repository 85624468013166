import AutomateLinkRow from "@/views/automate/AutomateLinkRow.vue";
import { supportedProcessors } from "@/views/automate/lib/processors";
export default {
    name: "AutomateLinks",
    components: { AutomateLinkRow },
    props: {
        configs: {
            type: Array,
        },
        links: {
            type: Array,
        },
    },
    data() {
        return {
            supported: supportedProcessors,
        };
    },
    methods: {
        logo(config) {
            let proc = this.supported.find((it) => it.name === config.name);
            return proc.logo;
        },
        configForLink(link) {
            const configs = this.configs;
            return configs?.find(it => it.id === link.configId);
        }
    },
};
