import StyleableLayout from "@/layouts/StyleableLayout.vue";
import LoadingButton from "@/components/LoadingButton.vue";
import GettingStarted from "@/views/automate/GettingStarted.vue";
import AutomateLinks from "@/views/automate/AutomateLinks.vue";
export default {
    components: { AutomateLinks, GettingStarted, LoadingButton, StyleableLayout },
    data() {
        return {
            configs: null,
            links: null,
        };
    },
    computed: {
        api() {
            return this.$store.getters["user/api"];
        },
        loggedIn() {
            return this.$store.getters["user/loggedIn"];
        },
        hasLinks() {
            return this.links?.length > 0;
        }
    },
    watch: {
        loggedIn(val, old) {
            if (!old && val) {
                this.loadProcessors();
            }
        },
    },
    methods: {
        login() {
            this.$bus.emit("login_request", {
                message: "",
            });
        },
        loadProcessors() {
            const api = this.api;
            if (this.loggedIn) {
                api.fiat.getUserConfigs().then((configs) => {
                    this.configs = configs;
                });
                api.fiat.getUserLinks().then((links) => {
                    this.links = links;
                });
            }
            else {
                this.configs = [];
                this.links = [];
            }
        },
    },
    created() {
        this.loadProcessors();
    },
};
