import BigNumber from "bignumber.js";
import LinkStatusMixin from "@/views/automate/LinkStatusMixin";
export default {
    name: "AutomateLinkRow",
    mixins: [LinkStatusMixin],
    props: {
        link: {
            type: Object,
        },
        config: {
            type: Object
        }
    },
    data() {
        return {
            token: null,
        };
    },
    computed: {
        api() {
            return this.$store.getters["user/api"];
        },
        price() {
            if (this.link) {
                return new BigNumber(this.link.productPrice).div(100).toFormat(2);
            }
        },
        currency() {
            return (this.link?.productCurrency || "USD").toUpperCase();
        },
        action() {
            if (!this.token) {
                return "Transfer";
            }
            let link = this.link;
            let action = this.link.action;
            if (action.type === "erc20-transfer") {
                return "Transfer " + action.value + " " + this.token.parameters.symbol;
            }
            else {
                return "Transfer " + this.token.name;
            }
        },
        tokenDeps() {
            return {
                account: this.$store.state.user.account,
                address: this.link.action.address,
                chain: this.link.action.chain,
            };
        },
    },
    watch: {
        tokenDeps: {
            immediate: true,
            deep: true,
            handler(val) {
                const api = this.api;
                if (val.address && val.chain) {
                    api.contracts
                        .getContract({ address: val.address, chainId: val.chain })
                        .then((it) => (this.token = it));
                }
            },
        },
    },
    methods: {
        click() {
            this.$router.push(`/automate/links/${this.link.id}`);
        }
    }
};
