import StyleableLayout from "@/layouts/StyleableLayout.vue";
import { supportedProcessors } from "@/views/automate/lib/processors";
import LoadingButton from "@/components/LoadingButton.vue";
export default {
    components: { LoadingButton, StyleableLayout },
    data() {
        return {
            supported: supportedProcessors,
            processor: null,
            key: "",
            submittingProcessor: false,
            processorError: null
        };
    },
    computed: {
        api() {
            return this.$store.getters["user/api"];
        },
    },
    methods: {
        addProcessor() {
            this.submittingProcessor = true;
            this.processorError = null;
            const api = this.api;
            api.fiat
                .addUserConfig({
                name: this.processor,
                key: this.key,
            })
                .then(() => {
                this.$router.push("/automate");
            })
                .catch((err) => {
                this.processorError = err.message;
            });
        },
    }
};
