import { Chain } from "@blockwell/chain-client";
import { getChain } from "@blockwell/chains";
import BigNumber from "bignumber.js";
const FIAT_RELAY = "0x3333333348C21b26bA7Ca211df9276aDB6C1AC84";
const PAYMENT_GAS = new BigNumber("120000");
const PAYMENT_BUFFER = new BigNumber("120000");
const APPROVE_GAS = new BigNumber("60000");
export async function fiatAccountStatus(config, link, api, batcher) {
    let [status, gas] = await Promise.all([fiatLinkStatus(config, link, api, batcher), fiatGas(link.action.chain)]);
    gas.notEnoughGas = status.ethBalance.lt(gas.minimumGas);
    gas.gasWarning = status.ethBalance.lt(gas.minimumGas.plus(gas.gasPerPayment.times(2)));
    return { status, gas };
}
export async function fiatLinkStatus(config, link, api, batcher) {
    const account = config.account;
    const token = await api.contracts.getContract({
        chainId: link.action.chain,
        address: link.action.address,
    });
    const net = getChain(token.network);
    let res = await batcher
        .batch(token)
        .setContract(token)
        .tryEthBalance("eth", account)
        .tryBig("balanceOf", "balance", [account])
        .execute();
    const balance = res.balance.div(`1e${token.parameters.decimals}`);
    const ethBalance = res.eth.div(`1e${net.decimals}`);
    let times;
    if (link.action.type === "erc20-transfer") {
        times = balance
            .div(new BigNumber(link.action.value).times(1.02))
            .dp(0, BigNumber.ROUND_FLOOR)
            .toNumber();
    }
    return {
        balance,
        ethBalance,
        times,
        notEnoughTokens: times === 0,
        tokenWarning: times >= 0 && times < 4,
    };
}
export async function fiatGas(chain) {
    const net = getChain(chain);
    let gas = await Chain.gasPrice(chain);
    let gasPrice;
    if (gas.type === 0) {
        gasPrice = new BigNumber(gas.gasPrice);
    }
    else {
        gasPrice = new BigNumber(gas.maxFeePerGas);
    }
    let minimumGas = gasPrice
        .times(APPROVE_GAS.plus(PAYMENT_GAS).plus(PAYMENT_BUFFER))
        .div(`1e${net.decimals}`);
    let gasPerPayment = gasPrice.times(PAYMENT_GAS).div(`1e${net.decimals}`);
    return { minimumGas, gasPerPayment, usesGas: gasPrice.gt(0), recommendedGas: minimumGas.plus(gasPerPayment.times(10)).sd(3) };
}
