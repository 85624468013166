import { supportedProcessors } from "@/views/automate/lib/processors";
import LoadingButton from "@/components/LoadingButton.vue";
export default {
    name: "GettingStarted",
    components: { LoadingButton },
    props: {
        configs: {
            type: Array
        }
    },
    data() {
        return {
            supported: supportedProcessors,
            processor: null,
            key: "",
            submittingProcessor: false,
            processorError: null
        };
    },
    computed: {
        api() {
            return this.$store.getters["user/api"];
        },
        loggedIn() {
            return this.$store.getters["user/loggedIn"];
        },
        configuredProcessor() {
            if (this.configs?.length > 0) {
                return this.supported.find((it) => it.name === this.configs[0].name);
            }
        },
    },
    methods: {
        login() {
            this.$bus.emit("login_request", {
                message: "",
            });
        },
        addProcessor() {
            this.submittingProcessor = true;
            this.processorError = null;
            const api = this.api;
            api.fiat
                .addUserConfig({
                name: this.processor,
                key: this.key,
            })
                .then(() => {
                this.$emit("update");
            })
                .catch((err) => {
                this.processorError = err.message;
            });
        },
    },
};
