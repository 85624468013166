import StyleableLayout from "@/layouts/StyleableLayout.vue";
import InfoSection from "@/views/happs/locks/InfoSection.vue";
import InfoField from "@/views/happs/locks/InfoField.vue";
import { supportedProcessors } from "@/views/automate/lib/processors";
import BigNumber from "bignumber.js";
import { getChain } from "@blockwell/chains";
import Clipboard from "@/components/Clipboard.vue";
import Info from "@/components/Info.vue";
import ColorInput from "@/components/color/ColorInput.vue";
import ColorField from "@/components/color/ColorField.vue";
import CodeBlock from "@/components/CodeBlock.vue";
import LinkStatusMixin from "@/views/automate/LinkStatusMixin";
import DumbappPopover from "@/components/DumbappPopover.vue";
import { every } from "@/lib/interval";
export default {
    components: {
        DumbappPopover,
        CodeBlock,
        ColorField,
        ColorInput,
        Info,
        Clipboard,
        InfoField,
        InfoSection,
        StyleableLayout,
    },
    mixins: [LinkStatusMixin],
    props: {
        id: String,
    },
    data() {
        return {
            link: null,
            config: null,
            errorMessage: null,
            buttonText: "Buy Now",
            buttonColor: "#004FFF",
            interval: null,
        };
    },
    computed: {
        processor() {
            if (this.config) {
                return supportedProcessors.find((processor) => this.config.name === processor.name);
            }
            return null;
        },
        processorLink() {
            if (this.config?.name === "stripe") {
                return `https://dashboard.stripe.com/payment-links/${this.link.id}`;
            }
            return null;
        },
        price() {
            if (this.link) {
                return new BigNumber(this.link.productPrice).div(100).toString(10);
            }
            return "-";
        },
        tokenLink() {
            if (this.token) {
                return getChain(this.token.network).explorerToken(this.token.address);
            }
            return null;
        },
        ethSymbol() {
            if (this.token) {
                return getChain(this.token.network).symbol;
            }
            return "";
        },
        dumbappQuery() {
            return {
                chain: this.link?.action?.chain,
                contract: this.link?.action?.address,
                to: this.config?.account
            };
        },
        embedCode() {
            return `<div style="overflow: auto; display: flex; justify-content: flex-end; align-items: center;
        width: 259px;
        background: #ffffff;
        border: 1px solid rgba(0, 0, 0, 0.1);
        border-radius: 10px;">
    <div style="padding: 20px">
        <a
            target="_blank"
            href="${this.link?.url}"
            style="
                display: inline-block;
                font-size: 18px;
                line-height: 48px;
                height: 48px;
                color: #ffffff;
                min-width: 212px;
                background-color: ${this.buttonColor};
                text-align: center;
                box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.1) inset;
                border-radius: 6px;
            "
            >${this.buttonText}</a
        >
    </div>
</div>`;
        },
    },
    watch: {
        embedCode(val) {
            if (this.$refs?.preview) {
                this.$refs.preview.innerHTML = val;
            }
        },
        id: {
            immediate: true,
            handler(val) {
                const api = this.api;
                api.fiat
                    .getLink(val)
                    .then((res) => {
                    this.config = res.config;
                    this.link = res.link;
                })
                    .catch((err) => {
                    this.errorMessage = err.message;
                });
            },
        },
    },
    mounted() {
        this.$refs.preview.innerHTML = this.embedCode;
        this.interval = every(() => {
            this.loadStatus(this.link);
        }, 15000, false);
    },
    beforeDestroy() {
        this.interval?.clear();
    }
};
