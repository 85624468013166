import StyleableLayout from "@/layouts/StyleableLayout.vue";
import { supportedProcessors } from "@/views/automate/lib/processors";
import ContractLoader from "@/views/happs/components/ContractLoader.vue";
import Asset from "@/components/Asset.vue";
import { getTokenData } from "@/lib/eth/Token";
import BigNumber from "bignumber.js";
import { getNetwork } from "@/assets/lib/networks";
import { getAddress } from "@blockwell/chain-client";
import LoadingButton from "@/components/LoadingButton.vue";
export default {
    components: { LoadingButton, Asset, ContractLoader, StyleableLayout },
    data() {
        return {
            configs: null,
            formErrors: [],
            form: {
                processor: null,
                productName: "",
                productPrice: "",
                tokenAddress: "",
                tokenChain: "",
            },
            actionTypes: [
                {
                    value: "erc20-transfer",
                    label: "Transfer ERC-20 Tokens",
                },
            ],
            token: null,
            submitted: false,
        };
    },
    computed: {
        api() {
            return this.$store.getters["user/api"];
        },
        loggedIn() {
            return this.$store.getters["user/loggedIn"];
        },
        configuredProcessor() {
            if (this.configs?.length > 0) {
                return this.supported.find((it) => it.name === this.configs[0].name);
            }
        },
        processors() {
            let configs = this.configs;
            return configs?.map((it) => {
                let proc = supportedProcessors.find((processor) => it.name === processor.name);
                return {
                    value: it.id,
                    label: proc.title,
                };
            });
        },
        tokenDeps() {
            return {
                account: this.$store.state.user.account,
                address: this.form.tokenAddress,
                chain: this.form.tokenChain,
            };
        },
    },
    watch: {
        tokenDeps: {
            deep: true,
            handler(val) {
                if (val.address && val.chain) {
                    getTokenData(val.chain, [val.address], val.account).then(async (res) => {
                        let token = res[0];
                        let data = {
                            ...token,
                            balance: new BigNumber(token.balance).div(`1e${token.decimals}`),
                        };
                        let net = getNetwork(data.network);
                        data.link = net.explorerToken(data.address, val.account);
                        if (net.networkId === 1) {
                            let checksum = getAddress(data.address);
                            data.logo = `https://raw.githubusercontent.com/trustwallet/assets/master/blockchains/ethereum/assets/${checksum}/logo.png`;
                        }
                        this.token = data;
                    });
                }
            },
        },
    },
    methods: {
        submit() {
            const api = this.api;
            const form = this.form;
            this.submitted = true;
            this.formErrors = [];
            let action;
            if (form.actionType === "erc20-transfer") {
                action = {
                    type: "erc20-transfer",
                    address: form.tokenAddress,
                    chain: form.tokenChain,
                    value: form.value,
                };
            }
            api.fiat
                .createLink({
                configId: form.processor,
                productName: form.productName,
                productPrice: new BigNumber(form.productPrice).times(100).toFixed(0),
                action,
            })
                .then((res) => {
                this.$router.push(`/automate/links/${res.id}`);
            })
                .catch((err) => {
                this.formErrors.push(err.message);
            })
                .finally(() => this.submitted = false);
        },
        loadProcessors() {
            const api = this.api;
            if (this.loggedIn) {
                api.fiat.getUserConfigs().then((configs) => {
                    this.configs = configs;
                });
            }
            else {
                this.configs = [];
            }
        },
        tokenContract(contract) {
            console.log(contract);
            this.form.tokenAddress = contract.address;
            this.form.tokenChain = contract.network;
        },
    },
    created() {
        this.loadProcessors();
    },
};
