import { render, staticRenderFns } from "./GettingStarted.vue?vue&type=template&id=0f1ae522&scoped=true&"
import script from "./GettingStarted.vue?vue&type=script&lang=ts&"
export * from "./GettingStarted.vue?vue&type=script&lang=ts&"
import style0 from "./GettingStarted.vue?vue&type=style&index=0&id=0f1ae522&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0f1ae522",
  null
  
)

export default component.exports